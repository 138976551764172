export default function HomePage() {
    return (
        <>
            <div className="container">
                <h1 className="fs-50">Table Pad Colors</h1>
                <div className="col-12 py-3">
                    <h2 className="fs-37">Leather Tones</h2>
                    <div className="row">
                        <div className="col-md-3 text-center pb-4">
                            <img src="/images/1_CRANBERRY.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2">Cranberry</h3>
                            Darker than cranberry sauce
                        </div>
                        <div className="col-md-3 text-center pb-4">
                            <img src="/images/2_NATURAL.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2">Natural</h3>
                            Cup of cream with a little coffee
                        </div>
                        <div className="col-md-3 text-center pb-4">
                            <img src="/images/3_MOCHA.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2">Mocha</h3>
                            Color of a coffee bean
                        </div>
                        <div className="col-md-3 text-center pb-4">
                            <img src="/images/4_PALOMINO.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2">Palomino</h3>
                            Looks like cappuccino
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/5_SADDLE.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2">Saddle</h3>
                            Cappuccino with double dairy
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/6_CHESTNUT.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2">Chestnut</h3>
                            A little darker than a chestnut
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/7_OPAL.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2">Opal</h3>
                            Whitish gray
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/LT_SLATE.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2">Slate</h3>
                            Medium gray or chalkboard
                        </div>

                    </div>
                </div>
                <hr />
                <div className="col-12 py-3">
                    <h2 className="fs-37">Wood Grains</h2>
                    <div className="row">
                        <div className="col-md-3 text-center pb-4">
                            <img src="/images/8_MAHOGANY.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2">Mahogany</h3>
                            Burgundy wine with a black wood grain
                        </div>
                        <div className="col-md-3 text-center pb-4">
                            <img src="/images/9_WALNUT.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2">Walnut</h3>
                            Milk chocolate with a dark chocolate grain
                        </div>
                        <div className="col-md-3 text-center pb-4">
                            <img src="/images/10_OAK.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2">Oak</h3>
                            Peanut butter with dark brown wood grain
                        </div>
                        <div className="col-md-3 text-center pb-4">
                            <img src="/images/11_CHERRY.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2">Cherry</h3>
                            Irish setter with brown streaks
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/12_BRANDY.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2">Brandy</h3>
                            A little darker than cherry
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/13_PECAN.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2">Pecan</h3>
                            A dark chocolate color with black wood grain
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/14_MAPLE.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2">Maple</h3>
                            Copper brown with dark brown wood grain
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/15_HICKORY.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2">Hickory</h3>
                            Looks like a snickerdoodle
                        </div>

                    </div>
                </div>
                <hr />
                <div className="col-12 py-3">
                    <h2 className="fs-37">Solids</h2>
                    <div className="row">
                        <div className="col-md-3 text-center pb-4">
                            <img src="/images/16_BLACK.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2">Black</h3>
                            Just black
                        </div>
                        <div className="col-md-3 text-center pb-4">
                            <img src="/images/17_BONE_WHITE.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2">Bone White</h3>
                            Ivory
                        </div>
                        <div className="col-md-3 text-center pb-4">
                            <img src="/images/18_WHITE.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2">White</h3>
                            Just white
                        </div>
                        <div className="col-md-3 text-center pb-4">
                            <img src="/images/19_COCOA.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2">Cocoa</h3>
                            Dark chocolate
                        </div>
                    </div>
                </div>
                <hr />
                <div className="col-12 py-3">
                    <h2 className="fs-37">Dura-Velvet</h2>
                    <div className="row pt-3">
                        <div className="col-md col-sm-12 text-center">
                            <img src="/images/20_BROWN.jpg" className="img-fluid w-100" />
                            <h3>Brown</h3>
                        </div>
                        <div className="col-md col-sm-12 text-center">
                            <img src="/images/21_TAN.jpg" className="img-fluid w-100" />
                            <h3>Tan</h3>
                        </div>
                        <div className="col-md col-sm-12 text-center">
                            <img src="/images/22_BURGUNDY.jpg" className="img-fluid w-100" />
                            <h3>Burgundy</h3>
                        </div>
                        <div className="col-md col-sm-12 text-center">
                            <img src="/images/23_BLACK.jpg" className="img-fluid w-100" />
                            <h3>Black</h3>
                        </div>
                        <div className="col-md col-sm-12 text-center">
                            <img src="/images/24_GREEN.jpg" className="img-fluid w-100" />
                            <h3>Green</h3>
                        </div>
                    </div>
                </div>
                <h1 className="fs-50">Table Linen Colors</h1>
                <div className="col-12 py-3">
                    <h2 className="fs-37">Cottunique Colors</h2>
                    <div className="row">
                        <div className="col-md-3 text-center pb-4">
                            <img src="/images/cottunique-antique-violet.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Antique Violet</h3>
                        </div>
                        <div className="col-md-3 text-center pb-4">
                            <img src="/images/cottunique-aqua.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Aqua</h3>
                        </div>
                        <div className="col-md-3 text-center pb-4">
                            <img src="/images/cottunique-black.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Black</h3>
                        </div>
                        <div className="col-md-3 text-center pb-4">
                            <img src="/images/cottunique-brown.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Brown</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-burgundy.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Burgundy</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-burnt-orange.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Burnt Orange</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-cafe.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Cafe</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-camel.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Camel</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-charcoal.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Charcoal</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-cherry.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Cherry</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-claret.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Claret</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-copper.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Copper</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-coral.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Coral</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-dark-olive.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Dark Olive</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-diana-gold.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Diana Gold</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-diana-seamist.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Diana Seamist</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-diana-wheat.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Diana Wheat</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-dusty.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Dusty</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-eggplant.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Eggplant</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-emerald.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Emerald</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-forest.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Forest</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-fudge.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Fudge</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-gold.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Gold</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-hawaiian-blue.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Hawaiian Blue</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-hazel.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Hazel</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-honeydew.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Honeydew</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-hot-pink.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Hot Pink</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-hunter.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Hunter</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-ivory.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Ivory</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-lemon.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Lemon</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-light-blue.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Light Blue</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-light-olive.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Light Olive</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-light-pink.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Light Pink</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-lilac.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Lilac</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-lime.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Lime</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-loden.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Loden</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-maize.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Maize</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-maroon.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Maroon</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-mustard.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Mustard</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-navy.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Navy</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-nutmeg.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Nutmeg</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-orange.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Orange</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-peach.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Peach</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-periwinkle.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Periwinkle</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-pink.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Pink</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-purple.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Purple</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-raspberry.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Raspberry</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-red.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Red</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-royal.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Royal</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-seamist.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Seamist</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-silver.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Silver</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-tan.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Tan</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-teal.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Teal</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-terra-cotta.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Terra Cotta</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-turquoise-sea.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Turquoise Sea</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-white.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">White</h3>
                        </div>
                        <div className="col-md-3 text-center py-4">
                            <img src="/images/cottunique-wisteria.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Wisteria</h3>
                        </div>

                    </div>
                </div>
                {/* <h2 className="fs-50 py-3">Kensington Damask Colors</h2>
                <div className="col-12 py-3">
                    <div className="row">
                        <div className="col-md-3 text-center pb-4">
                            <img src="/images/kensington-aubergine.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Aubergine</h3>
                        </div>
                        <div className="col-md-3 text-center pb-4">
                            <img src="/images/kensington-black.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Black</h3>
                        </div>
                        <div className="col-md-3 text-center pb-4">
                            <img src="/images/kensington-burgundy.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Burgundy</h3>
                        </div>
                        <div className="col-md-3 text-center pb-4">
                            <img src="/images/kensington-celadon.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Celadon</h3>
                        </div>
                        <div className="col-md-3 text-center pb-4">
                            <img src="/images/kensington-federal-blue.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Federal Blue</h3>
                        </div>
                        <div className="col-md-3 text-center pb-4">
                            <img src="/images/kensington-gold.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Gold</h3>
                        </div>
                        <div className="col-md-3 text-center pb-4">
                            <img src="/images/kensington-hunter.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Hunter</h3>
                        </div>
                        <div className="col-md-3 text-center pb-4">
                            <img src="/images/kensington-ivory.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Ivory</h3>
                        </div>
                        <div className="col-md-3 text-center pb-4">
                            <img src="/images/kensington-mauve.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Mauve</h3>
                        </div>
                        <div className="col-md-3 text-center pb-4">
                            <img src="/images/kensington-navy.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Navy</h3>
                        </div>
                        <div className="col-md-3 text-center pb-4">
                            <img src="/images/kensington-peach.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Peach</h3>
                        </div>
                        <div className="col-md-3 text-center pb-4">
                            <img src="/images/kensington-pewter.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Pewter</h3>
                        </div>
                        <div className="col-md-3 text-center pb-4">
                            <img src="/images/kensington-porcelain.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Porcelain</h3>
                        </div>
                        <div className="col-md-3 text-center pb-4">
                            <img src="/images/kensington-wheat.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">Wheat</h3>
                        </div>
                        <div className="col-md-3 text-center pb-4">
                            <img src="/images/kensington-white.jpg" className="img-fluid w-100" />
                            <h3 className="ColorName pt-2 YellowBox">White</h3>
                        </div>
                    </div>
                </div> */}
            </div>
            <footer className="footerClass"></footer>
        </>
    )
}